import { objectToCamel } from 'ts-case-convert';

export class Api {
    static APP_DISPATCHER_ADDRESS = process.env.REACT_APP_DISPATCHER_ADDRESS;
    static PIPELINE_ID = process.env.REACT_APP_CI_PIPELINE_IID;
    static COMMIT_ID = process.env.REACT_APP_CI_COMMIT_SHORT_SHA;
    static ARKIS_PORTAL_HTTP_URL = process.env.REACT_APP_ARKIS_PORTAL_HTTP_URL;
    static REACT_APP_ANALYTICS_HTTP_URL = process.env.REACT_APP_ANALYTICS_HTTP_URL;
    static API_AUTH_ENDPOINT = process.env.REACT_APP_API_AUTH_ENDPOINT;
    static WC_PROJECT_ID = process.env.REACT_APP_WC_PROJECT_ID;
    static WC_HOME_URL = process.env.REACT_APP_WC_HOME_URL;
    static CLOSE_SIGNATURE_TTL_SECS = process.env.REACT_APP_CLOSE_SIGNATURE_TTL_SECS;
    static WS_RPC_TIMEOUT_SECS = process.env.REACT_APP_WS_RPC_TIMEOUT_SECS;
    static ARKIS_PORTAL_WS_URL = process.env.REACT_APP_ARKIS_PORTAL_WS_URL;
    static WC_ETHEREUM_HTTP_URL = process.env.REACT_APP_WC_ETHEREUM_HTTP_URL;
    static API_AUTH_ENDPOINT_LOGOUT = 'https://dev-szrh2jq524m3wqi8.us.auth0.com/v2/logout';

    static hasRedirected = false;

    static async getResponse<T>(url: string, init?: RequestInit): Promise<T> {
        try {
            const urlWithApiKey = `${url}${url.includes('?') ? '&' : '?'}`;

            const data = await fetch(urlWithApiKey, {
                headers: {
                    ...init?.headers,
                },
                credentials: 'include',
                ...init,
            });

            if (data.status === 401) {
                return false as T;
            }

            if (data.ok) {
                const json = await data.json();
                return objectToCamel(json.result) as T;
            }
            return Promise.reject(data);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    static async postResponse<T>(url: string, body: any, init?: RequestInit): Promise<T> {
        try {
            const urlWithApiKey = `${url}${url.includes('?') ? '&' : '?'}`;

            const data = await fetch(urlWithApiKey, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...init?.headers,
                },
                body: JSON.stringify(body),
                credentials: 'include',
                ...init,
            });

            if (data.status === 401) {
                window.location.href = `${Api.API_AUTH_ENDPOINT}/start?rd=${window.location}`;
            }

            if (data.ok) {
                const responseBody = await data.text();

                if (!responseBody) {
                    return { result: 'Success' } as unknown as T;
                }

                return responseBody as unknown as T;
            }
            return Promise.reject(data);
        } catch (e) {
            return Promise.reject(e);
        }
    }
}
