import React, { FC, useEffect, useState } from 'react';
import { ButtonGroup, Pagination } from '@blink/components';
import { useDispatch, useSelector } from 'react-redux';
import { RadioChangeEvent } from 'antd';

import { useUserBorrowsSectionStyle } from './UserBorrowsSection.style';
import { UserBorrowsCard } from './UserBorrowsCard';
import { UserSuppliesConnectWallet, UserSuppliesEmpty } from '../../UserSupplies';
import { Store } from '../../../store';
import { BorrowStatusType, History, MaintenanceData } from '../../../api/borrow';
import { getTransformedUserBorrowList } from 'src/utils/helpers';
import { getUserBorrows } from 'src/store/borrows/actions';
import { useCentrifuge } from 'src/hooks/useCentrifuge';

const PULL_INTERVAL = 15000;
const STEP = 9;

const borrowStatusList = [
    {
        text: 'Opened',
        value: BorrowStatusType.OPENED,
    },
    {
        text: 'On Hold',
        value: BorrowStatusType.SUSPENDED,
    },
    {
        text: 'Closed',
        value: BorrowStatusType.CLOSED,
    },
];

export type UserBorrowItem = {
    address: string;
    id: number;
    apy: string;
    leverageAddress: string;
    name: string;
    symbol: string;
    decimals: number;
    amount: string;
    openedAt: string;
    maintenance: MaintenanceData;
    history: History<MaintenanceData>[];
};

export const UserBorrows: FC = () => {
    const dispatch = useDispatch();
    const classes = useUserBorrowsSectionStyle();
    const [page, setPage] = useState(0);
    const {
        walletAddress,
        blockchainName,
        marginAccount,
        userBorrows,
        userBorrowsSuspended,
        userBorrowsRegistered,
        userBorrowsClosed,
        loading,
    } = useSelector((state: Store) => ({
        walletAddress: state.wallets.active?.address,
        blockchainName: state.wallets.network,
        marginAccount: state.wallets.walletConnect?.connectedDapp?.marginAccount,
        userBorrows: state.borrows.userBorrows,
        userBorrowsSuspended: state.borrows.userBorrowsSuspended,
        userBorrowsRegistered: state.borrows.userBorrowsRegistered,
        userBorrowsClosed: state.borrows.userBorrowsClosed,
        loading: state.borrows.userBorrowsLoading,
    }));
    const [items, setItems] = useState<UserBorrowItem[]>([]);
    const { subscribeToChannel } = useCentrifuge();

    const [selectedBorrowStatusList, setSelectedBorrowStatusList] = useState(
        BorrowStatusType.OPENED,
    );

    const handleSelectedBorrowStatusListChange = (e: RadioChangeEvent) => {
        setSelectedBorrowStatusList(e.target.value);
    };

    const updateUserBorrowsState = (status: BorrowStatusType) => {
        switch (status) {
            case BorrowStatusType.OPENED:
                setItems(getTransformedUserBorrowList(userBorrows));
                userBorrows.forEach((item) => {
                    subscribeToChannel(`maintenance:${item.address}`);
                });

                break;
            case BorrowStatusType.SUSPENDED:
                setItems(
                    getTransformedUserBorrowList([
                        ...userBorrowsSuspended,
                        ...userBorrowsRegistered,
                    ]),
                );
                break;
            case BorrowStatusType.CLOSED:
                setItems(getTransformedUserBorrowList(userBorrowsClosed));
                break;
        }
    };

    useEffect(() => {
        updateUserBorrowsState(selectedBorrowStatusList);
    }, [userBorrows, userBorrowsSuspended, userBorrowsRegistered, userBorrowsClosed]);

    useEffect(() => {
        const callUserBorrows = () => {
            (async () => {
                if (!walletAddress) {
                    setItems([]);
                    return;
                }
                try {
                    dispatch(getUserBorrows(selectedBorrowStatusList));
                    if (selectedBorrowStatusList === BorrowStatusType.SUSPENDED) {
                        dispatch(getUserBorrows(BorrowStatusType.REGISTERED));
                    }
                    if (!loading) {
                        updateUserBorrowsState(selectedBorrowStatusList);
                    }
                } catch (e) {
                    setItems([]);
                    console.error(e);
                }
            })();
        };
        callUserBorrows();
        const interval = setInterval(() => {
            callUserBorrows();
        }, PULL_INTERVAL);

        return () => clearInterval(interval);
    }, [walletAddress, blockchainName, selectedBorrowStatusList]);

    const getContent = () => {
        if (!walletAddress) {
            return <UserSuppliesConnectWallet />;
        }
        if (!items.length) {
            let title = 'No opened margin accounts. Crypto awaits!';
            let subtitle = 'Create a new margin account to get started';

            if (selectedBorrowStatusList === BorrowStatusType.SUSPENDED) {
                title = "You currently don't have any margin accounts on hold";
                subtitle = 'Create a new margin account to get started';
            }
            if (selectedBorrowStatusList === BorrowStatusType.CLOSED) {
                title = "You currently don't have any closed margin accounts";
                subtitle = 'Create a new margin account to get started';
            }

            return (
                <UserSuppliesEmpty
                    customButtonName='Borrow'
                    customSubtitle={subtitle}
                    customTitle={title}
                />
            );
        }

        const cards = items
            .map((item, index) => {
                const isOnCurrentPage = index >= page * STEP && index < page * STEP + STEP;

                if (
                    selectedBorrowStatusList === BorrowStatusType.OPENED &&
                    (!item.maintenance || !item.history)
                ) {
                    return null;
                }

                return isOnCurrentPage ? (
                    <UserBorrowsCard
                        key={index}
                        connectedMarginAccount={marginAccount}
                        status={selectedBorrowStatusList}
                        {...item}
                    />
                ) : null;
            })
            .filter(Boolean);

        return (
            <>
                <div className={classes.cardsWrapper}>
                    {cards}
                    {page === 0 && items.length < 3}
                </div>
                <Pagination
                    currentPage={page}
                    pageSize={STEP}
                    totalItems={items.length}
                    onChange={setPage}
                    classes={{ root: classes.paginationWrapper }}
                />
            </>
        );
    };

    return (
        <div className={classes.root}>
            {walletAddress && (
                <ButtonGroup
                    items={borrowStatusList}
                    value={selectedBorrowStatusList}
                    handleChange={handleSelectedBorrowStatusListChange}
                    className={classes.borrowRatesListButtons}
                />
            )}

            {getContent()}
        </div>
    );
};
