import React from 'react';
import { Divider, Dropdown, MenuProps } from 'antd';
import { ReactComponent as LogoutIcon } from '@blink/assets/src/icons/logout.svg';
import { ReactComponent as SettingsIcon } from '@blink/assets/src/icons/settings.svg';
import { ReactComponent as QuestionIcon } from '@blink/assets/src/icons/question.svg';
import { ReactComponent as UserIcon } from '@blink/assets/src/icons/user.svg';
import { Api, ButtonStroke } from '@blink/components';
import { useSelector } from 'react-redux';

import { useUseControlsStyles } from './UserControls.style';

export const UserControls: React.FC = () => {
    const classes = useUseControlsStyles();

    const logout = `${Api.API_AUTH_ENDPOINT}/sign_out?rd=${Api.API_AUTH_ENDPOINT_LOGOUT}?returnTo=${window.location.origin}`;

    const meInfo = useSelector((state: any) => state.me.info);

    const items: MenuProps['items'] = [
        {
            type: 'divider',
        },
        {
            key: '1',
            label: <div className={classes.textButton}>Settings</div>,
            icon: <SettingsIcon />,
        },
        {
            key: '2',
            label: <div className={classes.textButton}>Help Center</div>,
            icon: <QuestionIcon />,
        },
        {
            type: 'divider',
        },
        {
            key: '3',
            label: (
                <a className={classes.textButton} href={logout}>
                    Log out
                </a>
            ),
            icon: <LogoutIcon />,
        },
    ];

    const menuStyle: React.CSSProperties = {
        boxShadow: 'none',
    };

    return (
        <Dropdown
            menu={{ items }}
            overlayClassName={classes.dropdownWQL}
            dropdownRender={(menu) => (
                <div>
                    <div className={classes.info}>
                        <div className='name'>{meInfo.name}</div>
                        <div>{meInfo.email}</div>
                    </div>
                    <Divider style={{ margin: 0 }} />
                    {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
                </div>
            )}
        >
            <ButtonStroke className={classes.root} onClick={(e) => e.preventDefault()}>
                <UserIcon />
                {meInfo.name}
            </ButtonStroke>
        </Dropdown>
    );
};
